@import url(https://fonts.googleapis.com/css?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gorditas:wght@400;700&display=swap);
body {
  background: #b8ecf9;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-page {
  background-color: #b8ecf9;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  
  width: 100%;
  position: absolute;
  background-image: url(https://res.cloudinary.com/color-connection/image/upload/v1587157354/KelleyMichelle-Portfolio/sky-wallpaper_fveuzg.png);
}

.greeting {
  font-family: 'Pacifico', cursive;
  font-size: 6em;
  color: #F76A8C;
  /* color: #fe346e; */
  margin-top: 15%;
  /* margin-bottom: 40%; */
}

#brand {
  font-family: 'Pacifico', cursive;
  color: #F76A8C;
  font-size: 1.5em;
}

.continue {
  /* font-family: 'Pacifico', cursive; */
  color: #F76A8C;
  /* color: #fe346e; */
  font-size: 2em;
  margin-top: 5%;
}

.about-me {
  background-color: #b8ecf9;
  background-image: url(https://res.cloudinary.com/color-connection/image/upload/v1587157355/KelleyMichelle-Portfolio/kite_u7tn8a.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 120%;
  width: 100%;
  position: absolute;
}

.portfolio {
  background-image: url(https://res.cloudinary.com/color-connection/image/upload/v1587157355/KelleyMichelle-Portfolio/portfolio_orfqd3.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 120%;
  width: 100%;
  position: absolute;
}

/* .portfolio.mobile {
  background-image: url(https://res.cloudinary.com/color-connection/image/upload/v1587157357/KelleyMichelle-Portfolio/portfolio-mobile_kqjgfk.png);
  width: 125%;
  z-index: 0;
} */

.portfolio.desktop {
  background-image: url(https://res.cloudinary.com/color-connection/image/upload/v1587157355/KelleyMichelle-Portfolio/portfolio_orfqd3.png);
}

#section {
  max-width: 1920px;
  height: 1000px;
}

#dropdown-link {
  color: navy;
}

.content-section {
  margin: 4%;
  font-weight: 700;
  /* font-family: 'Gorditas', cursive; */
}

.contact {
  /* background-image: url(./images/contactme.png); */
  background-image: url(https://res.cloudinary.com/color-connection/image/upload/v1587157377/KelleyMichelle-Portfolio/flowers_szzls5.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: absolute;
}

#carousel a div{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 900;
  color: black;
  background: rgba(255, 255, 255, 0.4);
  font-size: 2em;
}

#contact-form {
  color: navy;
  font-size: large;
  font-weight: 600;
  margin-left: 25px; 
  margin-right: 25px;
  max-width: 700px;
  ;
}

#name, #email {
  width: 250px;
  background: rgba(255, 255, 255, 0.8);
  margin: 3px;
}

#message {
  background: rgba(255, 255, 255, 0.8);
  height: 12em;
}

.j-card {
  margin: 30px;
  width: 280px;
  height: 360px;
  background-color: #e1ffda;
  box-shadow: 5px 5px 8px rgb(48, 48, 48);

  /* -webkit-transform: rotateY( 180deg );
  -moz-transform: rotateY( 180deg );
  -o-transform: rotateY( 180deg );
  transform: rotateY(180deg); */
}
/* dimensions for images */
.j-card-image {
  border-radius:3px;
  width: 280px;
  height: 360px;
  box-shadow: 5px 5px 8px rgb(48, 48, 48);
}

.flip-card:hover {
  /* transform: rotateY(180deg); */
  /* opacity: 0.3; */
  -webkit-transition: -webkit-transform 0.7s;
  transition: -webkit-transform 0.7s;
  transition: transform 0.7s;
  transition: transform 0.7s, -webkit-transform 0.7s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  /* -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1); */
}

@media screen and (max-width: 400px) {

  .portfolio.mobile {
    background-image: url(https://res.cloudinary.com/color-connection/image/upload/v1587157357/KelleyMichelle-Portfolio/portfolio-mobile_kqjgfk.png);
    width: 100%;
    height: 120%;
    z-index: -1;
  }

  section {
    position: absolute;
    width: 300px;
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }
  .set {
    position: absolute;
    width: 300px;
    height: 100%;
    top: 0;
    left: 0;
  }
}

#tile-box {
  position: relative;
  z-index: 5;
}

section {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}
.set {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 0;
  left: 100;
}
.set div {
  position: absolute;
  display: block;
}
.set div:nth-child(1) {
  left: -20%;
  -webkit-animation: animate 15s linear infinite;
          animation: animate 15s linear infinite;
  -webkit-animation-delay: -7s;
          animation-delay: -7s;
}
.set div:nth-child(2) {
  left: 50%;
  -webkit-animation: animate 20s linear infinite;
          animation: animate 20s linear infinite;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
}
.set div:nth-child(3) {
  left: -20%;
  -webkit-animation: animate 25s linear infinite;
          animation: animate 25s linear infinite;
}
.set div:nth-child(4) {
  left: -30%;
  -webkit-animation: animate 15s linear infinite;
          animation: animate 15s linear infinite;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
}
.set div:nth-child(5) {
  left: 85%;
  -webkit-animation: animate 18s linear infinite;
          animation: animate 18s linear infinite;
  -webkit-animation-delay: -10s;
          animation-delay: -10s;
}
.set div:nth-child(6) {
  left: -20%;
  -webkit-animation: animate 12s linear infinite;
          animation: animate 12s linear infinite;
}
.set div:nth-child(7) {
  left: 15%;
  -webkit-animation: animate 14s linear infinite;
          animation: animate 14s linear infinite;
}
.set div:nth-child(8) {
  left: 60%;
  -webkit-animation: animate 15s linear infinite;
          animation: animate 15s linear infinite;
}
.set div:nth-child(9) {
  left: -35%;
  -webkit-animation: animate 13s linear infinite;
          animation: animate 13s linear infinite;
}
.set div:nth-child(10) {
  left: 15%;
  -webkit-animation: animate 14s linear infinite;
          animation: animate 14s linear infinite;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
}
@-webkit-keyframes animate {
  0% {
    opacity: 0;
    top:-10%;
    -webkit-transform: translateX(20px) rotate(0deg);
            transform: translateX(20px) rotate(0deg);
  }
  10% {
    opacity: 1;
  }
  20% {
    -webkit-transform: translateX(-20px) rotate(45deg);
            transform: translateX(-20px) rotate(45deg);
  }
  40% {
    -webkit-transform: translateX(-20px) rotate(90deg);
            transform: translateX(-20px) rotate(90deg);
  }
  60% {
    -webkit-transform: translateX(20px) rotate(135deg);
            transform: translateX(20px) rotate(135deg);
  }
  80% {
    -webkit-transform: translateX(-20px) rotate(180deg);
            transform: translateX(-20px) rotate(180deg);
  }
  100% {
    top: 110%;
    -webkit-transform: translateX(-20px) rotate(225deg);
            transform: translateX(-20px) rotate(225deg);
  }
}
@keyframes animate {
  0% {
    opacity: 0;
    top:-10%;
    -webkit-transform: translateX(20px) rotate(0deg);
            transform: translateX(20px) rotate(0deg);
  }
  10% {
    opacity: 1;
  }
  20% {
    -webkit-transform: translateX(-20px) rotate(45deg);
            transform: translateX(-20px) rotate(45deg);
  }
  40% {
    -webkit-transform: translateX(-20px) rotate(90deg);
            transform: translateX(-20px) rotate(90deg);
  }
  60% {
    -webkit-transform: translateX(20px) rotate(135deg);
            transform: translateX(20px) rotate(135deg);
  }
  80% {
    -webkit-transform: translateX(-20px) rotate(180deg);
            transform: translateX(-20px) rotate(180deg);
  }
  100% {
    top: 110%;
    -webkit-transform: translateX(-20px) rotate(225deg);
            transform: translateX(-20px) rotate(225deg);
  }
}
.set2 {
  -webkit-transform: scale(2) rotateY(180deg);
          transform: scale(2) rotateY(180deg);
  -webkit-filter: blur(1px);
          filter: blur(1px);
}
.set3 {
  -webkit-transform: scale(0.8) rotateY(180deg);
          transform: scale(0.8) rotateY(180deg);
  -webkit-filter: blur(4px);
          filter: blur(4px);
}





.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

